<template>
  <div :class="[`ipost-listitem w-full flex p-2 items-${alignItems}`, { 'ipost-listitem-header': header }]">
    <!-- title -->
    <div v-if="selectable">
      <icon-checkbox class="mr-2" :value="selected" @input="select"></icon-checkbox>
    </div>
    <icon-image v-if="icon" :value="icon" :size="iconSize"></icon-image>
    <div class="flex flex-1" @click="clickContainer()">
      <div v-if="hasLeft" style="min-width: 120px;" :class="['flex-1 flex items-center', textContainerClass]" @click="clickLeft()">
        <div v-if="!window && textValue === 'Bank Transfer'">
          <div class="cursor-pointer pt-1" @click="$router.back()">
            <b-icon
                icon="chevron-left"
                size="is-medium">
            </b-icon>
          </div>
        </div>
        <slot name="text"><span v-if="textHtml" v-html="textHtml" :class="textClass"></span><span :class="textClass" :style="styleObj ? 'color: ' + styleObj.Color + '; fontSize: ' + styleObj.FontSize : ''" v-else>{{ textValue }}</span></slot>
      </div>
      <div v-if="rightText || $slots.righttext || rightTextPlaceholder || actions || textHtml" :class="[{ 'flex-1': !hasLeft }, rightTextContainerClass]">
        <div :class="['flex items-center', { 'w-full': typeof rightTextValue === 'undefined' }]" v-if="actions">
          <div v-for="(a, i) in actions" class="flex relative w-full" :key="i">
            <b-tag class="absolute" v-if="a.Badge" :type="a.Badge.Type" rounded style="top: 0; right: 0;z-index: 10">{{a.Badge.Value}}</b-tag>
            <b-button :tag="a.To ? 'router-link' : 'button'" v-if="a.Type" :class="[{ 'pr-0': i === (actions.length - 1) && a.Type === 'is-link' }, a.Class]" :type="a.Disabled ? undefined : a.Type" @click="a.Disabled ? undefined : clickAction({ action: a, data: $props })" :to="a.To">{{a.Text}}</b-button>
            <b-button :class="a.Class" :type="a.TypeColor || 'is-primary'" v-else-if="a.Text == 'Visit Now' && a.To.indexOf('http') !== -1"><a class="text-white" :href="a.To" target="_blank">{{ a.Text }}</a></b-button>
            <x-link v-else-if="a.To" :to="a.To" :class="[{ 'flex-1': actions.length === 1 }, 'block', a.Class]">{{a.Text}}</x-link>
            <span v-else :class="[a.Class, 'cursor-pointer block', { 'flex-1': actions.length === 1 }]" @click="$emit('action-click', { action: a, data: $props })">{{a.Text}}</span>
          </div>
        </div>
        <span v-else :class="['break-all', rightTextClass || textClass]" @click="$emit('click', data)">
          <slot name="righttext">{{ isNaN(rightTextValue) ? rightTextValue : (text === 'No Telepon' || text === 'Kode Pengguna') ? rightTextValue : formatNumber(rightTextValue) }}</slot>
        </span>
      </div>
    </div>
    <icon-image v-if="rightIcon" :value="rightIcon" :size="rightIconSize"></icon-image>
  </div>
</template>

<script>
import IconImage from './IconImage'
import IconCheckbox from './IconCheckbox'
import XLink from '../../link/Link'

export default {
  components: {
    IconImage,
    IconCheckbox,
    XLink
  },
  data () {
    return {
      selected: false,
      window: window.innerWidth < 767
    }
  },
  props: {
    header: {
      type: Boolean
    },
    icon: {
      type: String,
      required: false
    },
    iconSize: {
      type: String,
      default: '40px'
    },
    rightIcon: {
      type: String,
      required: false
    },
    rightIconSize: {
      type: String,
      default: '40px'
    },
    text: {
      type: [String, Number],
      required: false
    },
    styleObj: {
        type: Object,
        required: false
    },
    textPlaceholder: {
      type: [String, Boolean],
      required: false
    },
    textHtml: {
      type: String,
      required: false
    },
    rightText: {
      type: [String, Number],
      required: false
    },
    rightTextPlaceholder: {
      type: [String, Boolean],
      required: false
    },
    textClass: {
      type: [String, Array],
      required: false
    },
    textContainerClass: {
      type: [String, Array],
      required: false
    },
    rightTextClass: {
      type: [String, Array],
      required: false
    },
    rightTextContainerClass: {
      type: [String, Array],
      required: false
    },
    actions: {
      type: Array,
      required: false
    },
    placeholder: {
      type: [String, Boolean]
    },
    selectable: Boolean,
    alignItems: {
      type: String,
      default: 'center'
    }
  },
  methods: {
    select () {
      this.$emit('select', !this.selected)
      this.selected = !this.selected
    },
    emitClick () {
      this.$emit('click')
    },
    clickContainer () {
      if (!this.actions) this.emitClick()
    },
    clickLeft () {
      if (this.actions) this.emitClick()
    },
    clickAction (props) {
      if (props.action._TextToCopy) {
        this.copyText(props.action._TextToCopy)
        .then((res) => {
          // alert(`${props.action.Title} copied`)
          alert('Copied successfully')
        })
        .catch(() => {
          alert(`Failed to copy ${props.action.Title}`)
        })
      }
      this.$emit('action-click', props)
    }
  },
  computed: {
    hasLeft () {
      return typeof this.text !== 'undefined' || typeof this.textHtml !== 'undefined' || typeof this.textPlaceholder !== 'undefined' || this.$slots.text
    },
    textValue () {
      if (!this.text && this.textPlaceholder) {
        return typeof this.textPlaceholder === 'boolean' ? '-' : this.textPlaceholder
      }
      return this.text
    },
    rightTextValue () {
      if (!this.rightText && this.rightTextPlaceholder) {
        return typeof this.rightTextPlaceholder === 'boolean' ? '-' : this.rightTextPlaceholder
      }
      return this.rightText
    }
  }
}
</script>

<style>

</style>

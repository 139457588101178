var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.data.Card ? 'card' : 'div',{tag:"component",class:_vm.rowClasses},[(_vm.data.Title || _vm.data.RightTitle || _vm.data.TitlePlaceholder || _vm.data.RightTitlePlaceholder || _vm.data.TitleActions)?_c('list-item',{class:_vm.data.HeaderClass,attrs:{"text":_vm.data.Title,"text-class":['font-bold', _vm.data.TitleClass],"text-placeholder":_vm.data.TitlePlaceholder,"text-container-class":['font-bold', _vm.data.TitleContainerClass],"right-text":_vm.data.RightTitle,"right-text-class":_vm.data.RightTitleClass,"right-text-placeholder":_vm.data.RightTitlePlaceholder,"right-text-container-class":_vm.data.RightTitleContainerClass,"actions":_vm.data.TitleActions,"icon":_vm.data.TitleIcon,"icon-size":_vm.data.TitleIconSize,"right-icon":_vm.data.RightTitleIcon,"right-icon-size":_vm.data.RightTitleIconSize},on:{"click":function($event){return _vm.$emit('title-click', { data: _vm.data })},"action-click":function (ref) {
	var action = ref.action;

	return _vm.$emit('title-action-click', { action: action, data: _vm.data });
},"right-text-click":function($event){return _vm.$emit('right-title-click', _vm.data)}},scopedSlots:_vm._u([(_vm.$slots.title)?{key:"text",fn:function(){return [_vm._t("title")]},proxy:true}:null,(_vm.$slots.righttitle)?{key:"righttext",fn:function(){return [_vm._t("righttitle")]},proxy:true}:null],null,true)}):_vm._e(),(_vm.data.Text || _vm.data.TextHTML || _vm.data.RightText || _vm.data.TextPlaceholder || _vm.data.RightTextPlaceholder || _vm.data.Actions)?_c('list-item',{class:_vm.data.BodyClass,attrs:{"text":_vm.data.Text,"text-html":_vm.data.TextHTML,"text-class":_vm.data.TextClass,"text-placeholder":_vm.data.TextPlaceholder,"text-container-class":_vm.data.TextContainerClass,"right-text":_vm.data.RightText,"right-text-class":_vm.data.RightTextClass,"right-text-placeholder":_vm.data.RightTextPlaceholder,"right-text-container-class":_vm.data.RightTextContainerClass,"align-items":_vm.data.AlignText,"actions":_vm.data.Actions,"icon":_vm.data.Icon,"icon-size":_vm.data.IconSize,"right-icon":_vm.data.RightIcon,"right-icon-size":_vm.data.RightIconSize,"selectable":_vm.data.Selectable},on:{"select":function (v) { return _vm.$emit('select', v); },"click":_vm.click,"action-click":function (ref) {
	var action = ref.action;

	return _vm.$emit('action-click', { action: action, data: _vm.data });
},"right-text-click":function($event){return _vm.$emit('right-text-click', _vm.data)}},scopedSlots:_vm._u([(_vm.$slots.text)?{key:"text",fn:function(){return [_vm._t("text")]},proxy:true}:null,(_vm.$slots.righttext)?{key:"righttext",fn:function(){return [_vm._t("righttext")]},proxy:true}:null],null,true)}):_vm._e(),(_vm.data.Footer || _vm.data.RightFooter || _vm.data.FooterPlaceholder || _vm.data.RightFooterPlaceholder || _vm.data.FooterActions)?_c('list-item',{class:_vm.data.FooterClass,attrs:{"text":_vm.data.Footer,"styleObj":_vm.data.FooterStyle,"text-class":[_vm.data.FooterClass],"text-placeholder":_vm.data.FooterPlaceholder,"text-container-class":[_vm.data.FooterContainerClass],"right-text":_vm.data.RightFooter,"right-text-class":_vm.data.RightFooterClass,"right-text-placeholder":_vm.data.RightFooterPlaceholder,"right-text-container-class":_vm.data.RightFooterContainerClass,"actions":_vm.data.FooterActions,"icon":_vm.data.FooterIcon,"icon-size":_vm.data.FooterIconSize,"right-icon":_vm.data.FooterIcon,"right-icon-size":_vm.data.FooterIconSize},on:{"action-click":function (ref) {
	var action = ref.action;

	return _vm.$emit('footer-action-click', { action: action, data: _vm.data });
},"right-text-click":function($event){return _vm.$emit('right-footer-click', _vm.data)}},scopedSlots:_vm._u([(_vm.$slots.footer)?{key:"text",fn:function(){return [_vm._t("footer")]},proxy:true}:null,(_vm.$slots.rightfooter)?{key:"righttext",fn:function(){return [_vm._t("rightfooter")]},proxy:true}:null],null,true)}):_vm._e(),_vm._t("links",[(_vm.data.Links)?_c('div',{staticClass:"grid grid-cols-3 md:grid-cols-4 lg:grid-cols-lg gap-4"},[_vm._l((_vm.data.Links),function(l,i){return [_c('div',{key:i},[_c('img',{key:i,staticClass:"m-4 border border-solid",attrs:{"src":l.Icon,"alt":"","width":"30%"}}),(l.To)?_c('router-link',{key:i,staticClass:"p-2 text-blue-500",attrs:{"to":l.To}},[_vm._v(_vm._s(l.Text))]):_c('b-button',{key:i,staticClass:"px-2 md:px-4",style:({ 'justify-content': _vm.isSm ? 'center' : 'start' }),attrs:{"type":"is-link"},on:{"click":function($event){return _vm.$emit('link-click', l)}}},[_vm._v(_vm._s(l.Text))])],1)]})],2):_vm._e()]),(_vm.data.Tags)?_c('b-taglist',{staticClass:"mb-2 mr-2",staticStyle:{"justify-content":"flex-end"}},_vm._l((_vm.data.Tags),function(d,i){return _c('b-tag',{key:i,attrs:{"type":d.Type}},[_vm._v(_vm._s(d.Text))])}),1):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <span class="cursor-pointer" @click="input">
    <b-icon
      :icon="icon"
      :class="iconClasses"
      custom-size="mdi-24px">
    </b-icon>
  </span>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      currentValue: this.value
    }
  },
  methods: {
    input () {
      this.$emit('input', !this.value)
    }
  },
  computed: {
    icon () {
      if (this.value) return 'checkbox-marked'
      return 'checkbox-blank-outline'
    },
    iconClasses () {
      if (this.value) return 'text-blue-500'
      return 'text-gray-500'
    }
  },
  watch: {
    value (v) {
      this.currentValue = v
    }
  }
}
</script>

<style>

</style>

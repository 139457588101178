<template>
  <div class="bg-white p-4 rounded-lg">
    <slot></slot>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>

<template>
  <a v-if="isCordova && isExternal" @click="openLink(to)"><slot></slot></a>
  <a :href="to" v-else-if="isExternal" target="_blank"><slot></slot></a>
  <router-link :to="to" v-else><slot></slot></router-link>
</template>

<script>
export default {
  props: {
    to: String
  },
  computed: {
    isExternal () {
      return !this.to || this.to.indexOf('http') !== -1
    }
  }
}
</script>

<style>
</style>

<template>
  <component :is="data.Card ? 'card' : 'div'" :class="rowClasses">
    <!-- header -->
    <list-item
      v-if="data.Title || data.RightTitle || data.TitlePlaceholder || data.RightTitlePlaceholder || data.TitleActions"
      :class="data.HeaderClass"
      :text="data.Title"
      :text-class="['font-bold', data.TitleClass]"
      :text-placeholder="data.TitlePlaceholder"
      :text-container-class="['font-bold', data.TitleContainerClass]"
      :right-text="data.RightTitle"
      :right-text-class="data.RightTitleClass"
      :right-text-placeholder="data.RightTitlePlaceholder"
      :right-text-container-class="data.RightTitleContainerClass"
      :actions="data.TitleActions"
      :icon="data.TitleIcon"
      :icon-size="data.TitleIconSize"
      :right-icon="data.RightTitleIcon"
      :right-icon-size="data.RightTitleIconSize"
      @click="$emit('title-click', { data })"
      @action-click="({ action }) => $emit('title-action-click', { action, data })"
      @right-text-click="$emit('right-title-click', data)"
    >
      <template #text v-if="$slots.title"><slot name="title"></slot></template>
      <template #righttext v-if="$slots.righttitle"><slot name="righttitle"></slot></template>
    </list-item>
    <!-- body -->

    <list-item
      v-if="data.Text || data.TextHTML || data.RightText || data.TextPlaceholder || data.RightTextPlaceholder || data.Actions"
      :class="data.BodyClass"
      :text="data.Text"
      :text-html="data.TextHTML"
      :text-class="data.TextClass"
      :text-placeholder="data.TextPlaceholder"
      :text-container-class="data.TextContainerClass"
      :right-text="data.RightText"
      :right-text-class="data.RightTextClass"
      :right-text-placeholder="data.RightTextPlaceholder"
      :right-text-container-class="data.RightTextContainerClass"
      :align-items="data.AlignText"
      :actions="data.Actions"
      :icon="data.Icon"
      :icon-size="data.IconSize"
      :right-icon="data.RightIcon"
      :right-icon-size="data.RightIconSize"
      :selectable="data.Selectable"
      @select="v => $emit('select', v)"
      @click="click"
      @action-click="({ action }) => $emit('action-click', { action, data })"
      @right-text-click="$emit('right-text-click', data)"
    >
      <template #text v-if="$slots.text"><slot name="text"></slot></template>
      <template #righttext v-if="$slots.righttext"><slot name="righttext"></slot></template>
    </list-item>

    <!-- footer -->
    <list-item
      v-if="data.Footer || data.RightFooter || data.FooterPlaceholder || data.RightFooterPlaceholder || data.FooterActions"
      :class="data.FooterClass"
      :text="data.Footer"
      :styleObj="data.FooterStyle"
      :text-class="[data.FooterClass]"
      :text-placeholder="data.FooterPlaceholder"
      :text-container-class="[data.FooterContainerClass]"
      :right-text="data.RightFooter"
      :right-text-class="data.RightFooterClass"
      :right-text-placeholder="data.RightFooterPlaceholder"
      :right-text-container-class="data.RightFooterContainerClass"
      :actions="data.FooterActions"
      :icon="data.FooterIcon"
      :icon-size="data.FooterIconSize"
      :right-icon="data.FooterIcon"
      :right-icon-size="data.FooterIconSize"
      @action-click="({ action }) => $emit('footer-action-click', { action, data })"
      @right-text-click="$emit('right-footer-click', data)"
    >
      <template #text v-if="$slots.footer"><slot name="footer"></slot></template>
      <template #righttext v-if="$slots.rightfooter"><slot name="rightfooter"></slot></template>
    </list-item>

    <!-- links -->
    <slot name="links">
      <div v-if="data.Links" class="grid grid-cols-3 md:grid-cols-4 lg:grid-cols-lg gap-4">
        <template v-for="(l, i) in data.Links">
          <div :key="i">
            <img :src="l.Icon" alt="" width="30%" :key="i" class="m-4 border border-solid">
            <router-link v-if="l.To" :key="i" :to="l.To" class="p-2 text-blue-500">{{l.Text}}</router-link>
            <b-button v-else :key="i" type="is-link" class="px-2 md:px-4" :style="{ 'justify-content': isSm ? 'center' : 'start' }" @click="$emit('link-click', l)">{{l.Text}}</b-button>
          </div>
        </template>
      </div>
    </slot>

    <!-- tags -->
    <b-taglist v-if="data.Tags" class="mb-2 mr-2" style="justify-content: flex-end">
      <b-tag v-for="(d, i) in data.Tags" :key="i" :type="d.Type">{{d.Text}}</b-tag>
    </b-taglist>

  </component>
</template>

<script>
import ListItem from './common/ListItem'
import Card from './common/Card'
export default {
  components: {
    ListItem,
    Card
  },
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    click () {
      this.$emit('click', this.data)
    }
  },
  computed: {
    rowClasses () {
      const { Class, BorderWidth = 1, Type = 'listitem' } = this.data
      return ['w-full', {
        'border-b': BorderWidth === 1,
        [`border-b-${BorderWidth}`]: BorderWidth > 1,
        'border-red-200': BorderWidth !== 0,

        // type class
        'shadow rounded': Type === 'card'
      }, Class]
    }
  }
}
</script>

<style>

</style>
